//used for custom affix
;(function() {
  var throttle = function(type, name, obj) {
    obj = obj || window
    var running = false
    var func = function() {
      if (running) { return; }
      running = true
      requestAnimationFrame(function() {
        obj.dispatchEvent(new CustomEvent(name));
        running = false;
      })
    }
    obj.addEventListener(type, func);
  }

  /* init - you can init any event */
  throttle ("scroll", "optimizedScroll");
})()

$(function(){
  /*************************
   * CSRF TOKEN PROTECTION *
   ************************/
  var csrfTokenElem = $('#csrf-token');
  var csrfToken = csrfTokenElem.val();
  //add the CSRF token header to all ajax calls
  $.ajaxSetup({
    beforeSend: function(xhr) {
      xhr.setRequestHeader('X-CSRF-Token', $('#csrf-token').val());
    }
  });
  //insert the token into every form
  $('form').each(function(){
    var csrfTokenClone = csrfTokenElem.clone().removeAttr('id');
    $(this).append(csrfTokenClone);
  });

  // send custom events to posthog
  if(typeof(posthog) != 'undefined'){
    // beacon click event
    if(typeof(Beacon) != 'undefined') {
      Beacon('on', 'open', () => {
        posthog.capture('Beacon opened');
      })
    }
  }

  //better version of bootstrap affix
  function improvedAffix(elem, position, top, bottom) {
    window.addEventListener('optimizedScroll', function() {
      if(position == 'bottom' && ($(window).height() + window.scrollY - elem.outerHeight()) < top) {
        return elem
        .addClass('affix-top')
        .removeClass('affix')
      } else {
        return elem
        .removeClass('affix-top')
        .addClass('affix')
      }
    })
  }

  // trial author invite workflow
  if($('#inviteTrialUserModal').length > 0) {
    $('#inviteTrialUserModal').on('shown.bs.modal', function(){
      setTimeout(function(){$('#invite-name').focus();}, 150);
    });
    $('#invite-email').on('keyup', function(e){
      if($.trim($(this).val()) != '') {
        $(this).parent().removeClass('require-error');
      }
      if(e.key === "Enter") {
        $('#send-trial-invite').click();
      }
    })
    $('#invite-name').on('keyup', function(e){
      if($.trim($(this).val()) != '') {
        $(this).parent().removeClass('require-error');
      }
    })

    // create author and send invite
    $('#send-trial-invite').on('click', function(){
      var $kbID = $('#trial-invite-kb-id').val();
      var $nameElem = $('#invite-name');
      var $name = $.trim($nameElem.val());
      var $emailElem = $('#invite-email');
      var $email = $.trim($emailElem.val());

      // make sure they have a KB
      if($kbID.length < 1) {
        $('.message-error-text').text('You need to create a knowledge base first');
        $('.notify-message-error').hide().show(1);
        return false;
      }
      
      // validate we have a name
      if($name.length < 1) {
        $nameElem.parent().not('.require-error').addClass('require-error');
        $nameElem.focus();
        $('.message-error-text').text('Please add a name for the invite');
        $('.notify-message-error').hide().show(1);
        return false;
      }

      // simple check for valid emails
      var pattern = new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i);
      if(! pattern.test($email)) {
        $emailElem.parent().not('.require-error').addClass('require-error');
        var strLength = $email.length * 2;
        $emailElem.focus();$emailElem[0].setSelectionRange(strLength, strLength);
        $('.message-error-text').text('Please enter a valid email address');
        $('.notify-message-error').hide().show(1);
        return false;
      }

      // post the trial invite
      $('body').toggleClass('overlay');
      $('.hg-overlay').toggleClass('hide');
      $('.hg-spinner').toggleClass('hide');
      $.post('/app/trial-user-create', {
        kbID: $kbID,
        name: $name,
        email: $email
      }, function(response) {
        $('body').toggleClass('overlay');
        $('.hg-overlay').toggleClass('hide');
        $('.hg-spinner').toggleClass('hide');
        if(response.status == 'success') {
          $('#inviteTrialUserModal').modal('hide');
          $nameElem.val('');
          $emailElem.val('');
          $('.message-success-text').text(response.msg);
          $('.notify-message-success').hide().show(1);
        } else if(response.status == 'error') {
          $('.message-error-text').text(response.reason);
          $('.notify-message-error').hide().show(1);
        } else {
          $('.message-error-text').text('Something went wrong. Please reload the page and try again.');
          $('.notify-message-error').hide().show(1);
        }
      });
    });
  }

  //check current login session is valid
  var pathname = window.location.pathname;
  if(pathname.indexOf('/login') < 0 && pathname.indexOf('/admin') < 0) {
    setInterval(sessionCheck, 15 * 1000);
  }
  function sessionCheck() {
    if ($('#session-expired').css('display') != 'block') {
      $.get('/app/session-check').done(function(data){
        if(!data.valid || data.valid != true) {
          //they are logged out so show a the modal
          $('body').toggleClass('overlay');
          $('.hg-overlay').toggleClass('hide');
          $('#session-expired').modal('show');
        }
        else {
          if (data.csrf) {
            $('[name="csrf-token"]').val(data.csrf);
          }
        }
      }, "json");
    }
  }

  //when they close the session modal, immediately check for active session
  $('#session-expired').on('hidden.bs.modal', function(){
    $('body').toggleClass('overlay');
    $('.hg-overlay').toggleClass('hide');
    sessionCheck();
  });

  //apply the affix rules
  $('.ko-affix').each(function(){
    improvedAffix($(this), $(this).data('position'), $(this).data('top'), $(this).data('bottom'));
  });

  //scrolling containers
  $('.max-height-inner').each(function(){
    var totalChildrenHeight = 0;
    $(this).children().each(function(){
      totalChildrenHeight = totalChildrenHeight + $(this).actual('outerHeight', {includeMargin : true});
    });
    if(totalChildrenHeight > 310)
      $(this).parent().addClass('overflow');
  });

  //slidedown dropdown trigger
  $('.select.slidedown-trigger').on('click', function(){
    $(this).toggleClass('open');
  })

  //fixed save bar
  if($('.fixed-bottom-save-bar').length > 0) {
    var throttle = function(type, name, obj) {
      obj = obj || window
      var running = false
      var func = function() {
        if (running) { return; }
        running = true
        requestAnimationFrame(function() {
          obj.dispatchEvent(new CustomEvent(name));
          running = false;
        })
      }
      obj.addEventListener(type, func);
    }
    throttle ("scroll", "optimizedScroll");

    var fixedSaveBar = $('.fixed-bottom-save-bar');
    var topStartElem = $('.fixed-save-bar-start');
    var topStartPos = topStartElem.length > 0 ? (topStartElem.offset().top + topStartElem.outerHeight()) : 0;
    window.addEventListener('optimizedScroll', function() {
      if (window.scrollY >= 0 && window.scrollY < topStartPos && fixedSaveBar.hasClass('shown')) {
        if($('.scrolling-bar-dropdown').hasClass('open') > 0){
          $('.scrolling-bar-dropdown > .dropdown-toggle').dropdown('toggle');
          $('.fixed-dropdown > .dropdown-toggle').dropdown('toggle');
        }
        $('body').removeClass('fixed-saved-bar');
        return fixedSaveBar.removeClass('shown');
      } else if(window.scrollY >= topStartPos && !fixedSaveBar.hasClass('shown')) {
        if($('.fixed-dropdown').hasClass('open') > 0){
          $('.fixed-dropdown > .dropdown-toggle').dropdown('toggle');
          $('.scrolling-bar-dropdown > .dropdown-toggle').dropdown('toggle');
        }
        $('body').not('.fixed-save-bar').addClass('fixed-saved-bar');
        return fixedSaveBar.addClass('shown');
      } else {
        return false;
      }
    });
  }
});